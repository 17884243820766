.no-data[data-v-58fca26f] {
  width: 1.2rem;
  margin: 0.5rem auto;
  text-align: center;
}
.no-data > img[data-v-58fca26f] {
    width: 100%;
}
.no-data > div[data-v-58fca26f] {
    color: #aaa;
    font-size: 0.14rem;
    margin-top: 0.1rem;
}
@media screen and (min-width: 768px) {
.no-data[data-v-58fca26f] {
    width: 250px;
    margin: 30px auto;
}
.no-data > div[data-v-58fca26f] {
      font-size: 14px;
      margin-top: 20px;
}
}
